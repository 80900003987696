import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { signin } from './api-auth.js';
import auth from './../auth/auth-helper';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLogout } from '../auth/actions/actions';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { currentUser } from "../components/Usuario/api-usuario";
import { setUsuario } from '../components/Usuario/actions/actions';
import GooglePlay from '../shared/assets/img/disponivel-google-play-badge.png';
import AppStore from '../shared/assets/img/disponivel_app_store_2.png';
import { errorMessage } from '../util/ConfirmAlertUtil.js';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    logo: {
        width: '100%',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    createInstitution: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: 'green',
        color: 'white'
    },
    loading: {
        margin: 'auto'
    }
}));

export default function SignIn() {
    const classes = useStyles();
    const [user, setUser] = useState({
        username: '',
        password: ''
    });
    const [authenticated, setAuthenticated] = useState(false);
    const dispatch = useDispatch();
    const [logoUrl, setLogoUrl] = useState('');

    useEffect(() => {
        document.title = "Sign In"
    }, []);

    useEffect(() => {
        const jwt = auth.isAuthenticated();
        if (jwt) {
            setAuthenticated(true);
            currentUser().then(data => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    dispatch(setUsuario(data));
                }
            })
        }
        switch (window.location.href) {
            case 'http://localhost:3000/':
                setLogoUrl('https://colesan-online-resources.s3.us-east-2.amazonaws.com/Logoblazon+Colesan+Online.png');
                break;

            case 'https://www.colesanonline.com.br/':
                setLogoUrl('https://colesan-online-resources.s3.us-east-2.amazonaws.com/Logoblazon+Colesan+Online.png');
                break;

            default:
                setLogoUrl('');
        }

    }, [dispatch]);

    const clickSubmit = (event) => {
        event.preventDefault();

        signin(user).then((response) => {
            if (response.ok) {
                dispatch(setLogout(false));
                const jwtToken = response.headers.get('Authorization');
                localStorage.setItem('token', jwtToken);
                setAuthenticated(true);
            } else {
                if (response.status === 403) {
                    toast.error("Acesso Negado", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                } else {
                    toast.error("Erro", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                }
            }
        }).catch(err => {
            if (err.message === "Failed to fetch") {
                toast.error("Ocorreu um erro. Tente novamente mais tarde.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
            console.log(err);
        });
    }

    const handleChange = (event) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value
        })
    }

    if (authenticated) {
        return <Redirect to='/home' />
    } else {
        return (
            <div>
                <CssBaseline />
                <ToastContainer autoClose={3000} />
                <Container component="main" maxWidth="xs">

                    <div className={classes.paper}>
                        <img src={logoUrl}
                            className={classes.logo} alt="Logo" />
                        <Typography className={classes.title} variant="h5">
                            Portal Acadêmico
                        </Typography>
                        <form onSubmit={clickSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Email"
                                name="username"
                                autoComplete="email"
                                autoFocus
                                onChange={handleChange}
                                value={user.username || ''}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Senha"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={handleChange}
                                value={user.password || ''}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Acessar
                            </Button>
                        </form>
                        <Button
                            component={Link}
                            to={{
                                pathname: '/request-reset-password'
                            }}
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className={classes.submit}>
                            Esqueci a senha
                        </Button>
                    </div>
                    <div>
                        <a href="https://play.google.com/store/apps/details?id=com.colesan">
                            <img style={{ width: '100px' }} src={GooglePlay} alt='' />
                        </a>
                        <a href="https://apps.apple.com/br/app/colesan-online/id1555752723">
                            <img style={{ width: '100px', marginLeft: '30px' }} src={AppStore} alt='' />
                        </a>
                    </div>
                </Container>
            </div>
        );
    }
}