import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TDPTableSelectRedux from '../../TDP/TDPTableSelectRedux';
import TurmaDisciplinaProfessorProfileRedux from '../../TDP/TurmaDisciplinaProfessorProfileRedux';
import { useSelector } from 'react-redux';
import AusenciaCompensadaForm from './AusenciaCompensadaForm';
import AusenciaCompensadaTable from './AusenciaCompensadaTable';
import { Grid, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { createUpdate } from '../api-aluno-frequencia';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import { toast } from 'react-toastify';
import { ADMIN } from '../../Usuario/Perfil';
import LoadingDialog from '../../UI/LoadingDialog';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    title: {
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

export default function AusenciaCompensadaContainer() {
    const classes = useStyles();
    const tdp = useSelector(state => state.tdp);
    const alunoFrequencia = useSelector(state => state.alunoFrequencia);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const usuario = useSelector(state => state.usuario);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleCreateUpdate = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja criar/atualizar todos registros de aluno freq. da instituição?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setOpenDialog(true);
                        createUpdate().then((data) => {
                            if (data.error) {
                                errorMessage(data.message);
                            } else {
                                setOpenDialog(false);
                                toast.success("Operação realizada com sucesso.", {
                                    position: toast.POSITION.BOTTOM_LEFT
                                });
                            }
                        })
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    return (
        <Container className={classes.paper} component={Paper}>
            <LoadingDialog
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                title="Aluno Frequência"
                description='Criando/Atualizando'
            />
            <Grid container direction='row' justifyContent='space-between'>
                <Grid item>
                    <Typography className={classes.title} variant="h6">
                        Ausência Compensada
                    </Typography>
                </Grid>
                <Grid item>
                    {usuario.perfil === ADMIN ?
                        <>
                            <MenuIcon
                                fontSize='medium'
                                className={classes.cursorLink}
                                onClick={(event) => handleMenuClick(event)}
                                color="secondary"

                            />
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem
                                    style={{
                                        fontSize: 12
                                    }}
                                    onClick={() => handleCreateUpdate()}
                                >
                                    Criar/Atualizar Aluno Freq. Toda Instituição
                                </MenuItem>
                            </Menu>
                        </> : ''
                    }

                </Grid>
            </Grid>
            {tdp ?
                <>
                    <TurmaDisciplinaProfessorProfileRedux />
                    {alunoFrequencia ? <AusenciaCompensadaForm /> : <AusenciaCompensadaTable />}

                </> :
                <TDPTableSelectRedux />
            }
        </Container>
    );
}