import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { rltFrequenciaWeb, rltFrequenciaWebTdp } from "../../api-relatorio";
import RelatorioCabecalhoWeb from '../Cabecalho/RelatorioCabecalhoWeb';
import { errorMessage } from '../../../../util/ConfirmAlertUtil';
import Loading from '../../../UI/Loading';
import RelatorioCabecalhoWebBimestre from '../Cabecalho/RelatorioCabecalhoWebBimestre';
import RelatorioFrequenciaWebAlunoFrequencia from './RelatorioFrequenciaWebAlunoFrequencia';
import { dateFormat } from '../../../../util/DateUtil';
import { Grid } from '@material-ui/core';
import RelatorioFrequenciaWebTdp from './RelatorioFrequenciaWebTdp';
import { lightList } from '../../../UI/ColorUtil';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
        backgroundColor: 'white',
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px'

    },
    adicionarDisciplina: {
        paddingBottom: '10px',
    },
    formControl: {
        marginBottom: '10px'
    }
});

export default function RelatorioFrequenciaWeb(props) {
    const classes = useStyles();
    const [frequencia, setFrequencia] = useState();
    const { codAlunoTurma, codTdp } = props.location.state;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (codAlunoTurma && codTdp) {
            rltFrequenciaWebTdp(codAlunoTurma, codTdp).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setFrequencia(data);
                }
                setIsLoading(false);
            })
        } else {
            rltFrequenciaWeb(codAlunoTurma).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setFrequencia(data);
                }
                setIsLoading(false);
            })
        }


    }, [codAlunoTurma, codTdp]);

    return (
        <Container className={classes.paper} component={Paper}>
            <Button
                onClick={() => props.history.goBack()}
                variant="contained"
                color="secondary"
                className={classes.voltarButton}
            >
                <ArrowBackIcon style={{ marginRight: '10px' }} />
                Voltar
            </Button>
            <Typography className={classes.title} variant="h5">
                Frequência
            </Typography>
            {isLoading ?
                <Loading description='Carregando Relatório...' /> :
                <>
                    <RelatorioCabecalhoWeb alunoTurma={frequencia.alunoTurma} />
                    {frequencia.listFreqTdp.map((freqTdp, index) => (
                        <div key={index}>
                            <RelatorioFrequenciaWebTdp
                                freqTdp={freqTdp}
                                setFrequencia={setFrequencia}
                                bgColor={lightList[index]}
                            >
                                <Grid container spacing={3} direction='column'>
                                    <Grid item>
                                        {freqTdp.listFreqBim.map((freqBim, index) => (
                                            <Grid container spacing={2} direction='column' key={index}>
                                                {freqTdp.tdp.eProjeto ?
                                                    '' :
                                                    <Grid item>
                                                        <RelatorioCabecalhoWebBimestre bimestre={freqBim.bimestre} />
                                                    </Grid>
                                                }
                                                <Grid item>
                                                    <RelatorioFrequenciaWebAlunoFrequencia alunoFrequencia={freqBim.alunoFrequencia} />
                                                </Grid>
                                                <Grid item>
                                                    {freqBim.listPA.length > 0 ?
                                                        <Typography variant='body'>Datas: </Typography> :
                                                        ''
                                                    }
                                                </Grid>
                                                <Grid item>
                                                    <Grid container spacing={3} direction='row'>
                                                        {freqBim.listPA.map((pa) => (
                                                            <Grid
                                                                key={pa.codigo}
                                                                item
                                                                style={{
                                                                    backgroundColor: pa.legendaDiario.codigo === 1 ? '#e0ffcd' : '#ffaaa5',
                                                                    borderRadius: '20px',
                                                                    borderStyle: 'solid',
                                                                    borderColor: pa.legendaDiario.codigo === 1 ? '#cbf078' : '#ff5757',
                                                                    borderWidth: '5px',
                                                                    margin: '10px',
                                                                    padding: '10px',
                                                                }}
                                                            >{dateFormat(pa.presenca.data)}</Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Grid item>
                                        <Typography style={{ textAlign: 'center' }}>Frequência Total: {freqTdp.frequenciaTotal}</Typography>
                                    </Grid>
                                </Grid>
                            </RelatorioFrequenciaWebTdp>
                        </div>
                    ))}

                </>

            }







        </Container>
    );
}